@keyframes pricechange {
  from {
    background-color: #0004C7;
  }
  to {
    background-color: #0004C7;
  }
}
body, #app {
  overflow: auto;
  overflow-y: scroll;
}

.new-index-page {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.new-index-page .bitvps-logo {
  display: inline-block;
  height: 20px;
  position: relative;
  top: 6px;
  margin-right: 3px;
}
.new-index-page dl.exchanges {
  text-align: left;
  padding: 0;
  margin: 0;
  font-family: Arial, sans;
}
.new-index-page dl.exchanges dd {
  color: #EEE;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #111;
  margin: 3px;
  padding: 3px 6px;
  border-radius: 3px;
  cursor: pointer;
}
.new-index-page dl.exchanges dd a {
  color: #EEE;
}
.new-index-page dl.exchanges dd.c-bitstamp {
  background-color: #363;
}
.new-index-page dl.exchanges dd.c-btce, .new-index-page dl.exchanges dd.c-wex {
  background-color: #039;
}
.new-index-page dl.exchanges dd.c-huobi, .new-index-page dl.exchanges dd.c-bithumb {
  background-color: #600;
}
.new-index-page dl.exchanges dd.c-okcoin {
  background-color: #036;
}
.new-index-page dl.exchanges dd.c-cavirtex, .new-index-page dl.exchanges dd.c-bisq {
  background-color: #069;
}
.new-index-page dl.exchanges dd.c-kraken {
  background-color: #56A3C9;
}
.new-index-page dl.exchanges dd.c-btcchina, .new-index-page dl.exchanges dd.c-exmo {
  background-color: #369;
}
.new-index-page dl.exchanges dd.c-bitfinex {
  background-color: #06C;
}
.new-index-page dl.exchanges dd.c-bitcoinde {
  background-color: #36C;
}
.new-index-page dl.exchanges dd.c-bter {
  background-color: #099;
}
.new-index-page dl.exchanges dd.c-coinbase, .new-index-page dl.exchanges dd.c-gdax {
  background-color: #3882CD;
}
.new-index-page dl.exchanges dd.c-campbx {
  background-color: #630;
}
.new-index-page dl.exchanges dd.c-vos {
  background-color: #366;
}
.new-index-page dl.exchanges dd.c-mintpal {
  background-color: #006B4D;
}
.new-index-page dl.exchanges dd.c-cryptsy {
  background-color: #069;
}
.new-index-page dl.exchanges dd.c-cexio {
  background-color: #096;
}
.new-index-page dl.exchanges dd.c-bittrex {
  background-color: #2C3742;
}
.new-index-page dl.exchanges dd.c-poloniex {
  background-color: #5A826E;
}
.new-index-page dl.exchanges dd.c-mercado {
  background-color: #16283A;
}
.new-index-page dl.exchanges dd.c-quadrigacx, .new-index-page dl.exchanges dd.c-binance {
  background-color: #2C2C2C;
}
.new-index-page main {
  flex: 1 0;
  font-size: 14px;
  clear: both;
  text-align: center;
}
.new-index-page main > div {
  display: inline-block;
}
.new-index-page main .paginator {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.new-index-page main .paginator .left, .new-index-page main .paginator .right {
  flex-grow: 1;
  flex-basis: 0;
}
.new-index-page main .paginator .left {
  text-align: left;
}
.new-index-page main .paginator .right {
  text-align: right;
}
.new-index-page main .paginator ul {
  display: inline-block;
  list-style: none;
}
.new-index-page main .paginator ul li {
  display: inline-block;
  margin-right: 18px;
}
.new-index-page main .paginator ul .active > a {
  color: var(--active-link-color-2);
}
.new-index-page main g {
  color: #222;
}
.new-index-page main .red {
  color: #C00;
}
.new-index-page main .green {
  color: #0C0;
}
.new-index-page main .red.percent {
  background-color: #630;
}
.new-index-page main .green.percent {
  background-color: #363;
}
.new-index-page main .promoted {
  max-width: 1100px;
  padding: 100px 0;
  margin-top: 40px;
}
.new-index-page main table {
  border-collapse: collapse;
  margin: auto;
  /*thead td {
      color: var(--text-color-50-darker);
  }*/
}
.new-index-page main table .fa-caret-down, .new-index-page main table .fa-caret-up {
  color: var(--text-color-25-darker);
}
.new-index-page main table caption {
  margin: 40px 0 20px;
  caption-side: top;
  vertical-align: middle;
}
.new-index-page main table caption h2 {
  text-align: left;
}
.new-index-page main table caption label {
  text-align: center;
  font-size: large;
  float: left;
}
.new-index-page main table caption input {
  text-align: center;
  width: 50%;
}
.new-index-page main table caption > div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-border-color);
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.new-index-page main table caption > div ul li {
  display: inline-block;
}
.new-index-page main table caption > div ul li.active > a {
  color: var(--active-link-color-2);
}
.new-index-page main table thead td {
  text-align: center;
  vertical-align: middle;
}
.new-index-page main table .price-change {
  animation-duration: 3s;
  animation-name: pricechange;
  color: var(--button-hover-bg-color);
}
.new-index-page main table td {
  text-align: right;
  vertical-align: middle;
  padding: 3px 6px;
  border: 1px solid var(--light-border-color);
  white-space: nowrap;
}
.new-index-page main table td img.icon {
  height: 24px;
}
.new-index-page main table td.name {
  text-align: left;
  font-weight: bold;
  margin: 0 10px;
}
.new-index-page main table td.name > div {
  display: flex;
  align-items: center;
}
.new-index-page main table td.name > div img {
  user-select: none;
  padding: 0 7px;
}
.new-index-page main table td.no-r {
  border-right: none;
  padding-left: 16px;
}
.new-index-page main table td.no-l {
  border-left: none;
  padding-right: 16px;
}
.new-index-page main table td.r {
  text-align: right;
}
.new-index-page main table td.c {
  text-align: center;
}
.new-index-page main table td.active {
  font-weight: bold;
}
.new-index-page main table td.pointer {
  cursor: pointer;
}
.new-index-page footer {
  line-height: 28px;
  font-size: 12px;
  margin-top: 36px;
  border-top: 1px solid var(--light-border-color);
}
.new-index-page footer .inner {
  display: flex;
  justify-content: space-between;
  margin: 4px 6px;
}
.new-index-page footer .time {
  color: var(--text-color-25-darker);
}
.new-index-page footer ul.horiz > li {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: baseline;
  margin-right: 10px;
}

.navbar .nav .logo {
  margin-right: 18px;
}

@media only screen and (min-width: 0px) and (min-height: 0px) {
  div[id^=bsa-zone_1585587375466-4_123456] {
    min-width: 320px;
    min-height: 50px;
  }
}
@media only screen and (min-width: 770px) and (min-height: 0px) {
  div[id^=bsa-zone_1585587375466-4_123456] {
    min-width: 728px;
    min-height: 90px;
  }
}