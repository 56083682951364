@keyframes pricechange {
  from {
    background-color: #0004C7;
  }

  to {
    background-color: #0004C7;
  }
}


body, #app {
  overflow: auto;
  overflow-y: scroll;
}

.new-index-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  .bitvps-logo {
    display: inline-block;
    height: 20px;
    position: relative;
    top: 6px;
    margin-right: 3px;
  }

  dl.exchanges {
    text-align: left;
    padding: 0;
    margin: 0;
    font-family: Arial, sans;
  }

  dl.exchanges dd {
    color: #EEE;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-color: #111;
    margin: 3px;
    padding: 3px 6px;
    border-radius: 3px;
    cursor: pointer;

    a {
      color: #EEE;
    }

    &.c-bitstamp {
      background-color: #363;
    }

    &.c-btce, &.c-wex {
      background-color: #039;
    }

    &.c-huobi, &.c-bithumb {
      background-color: #600;
    }

    &.c-okcoin {
      background-color: #036;
    }

    &.c-cavirtex, &.c-bisq {
      background-color: #069;
    }

    &.c-kraken {
      background-color: #56A3C9;
    }

    &.c-btcchina, &.c-exmo {
      background-color: #369;
    }

    &.c-bitfinex {
      background-color: #06C;
    }

    &.c-bitcoinde {
      background-color: #36C;
    }

    &.c-bter {
      background-color: #099;
    }

    &.c-coinbase, &.c-gdax {
      background-color: #3882CD;
    }

    &.c-campbx {
      background-color: #630;
    }

    &.c-vos {
      background-color: #366;
    }

    &.c-mintpal {
      background-color: #006B4D;
    }

    &.c-cryptsy {
      background-color: #069;
    }

    &.c-cexio {
      background-color: #096;
    }

    &.c-bittrex {
      background-color: #2C3742;
    }

    &.c-poloniex {
      background-color: #5A826E;
    }

    &.c-mercado {
      background-color: #16283A;
    }

    &.c-quadrigacx, &.c-binance {
      background-color: #2C2C2C;
    }
  }

  main {
    flex: 1 0;
    font-size: 14px;
    clear: both;
    text-align: center;

    > div {
      display: inline-block;
    }

    .paginator {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;

      .left, .right {
        flex-grow: 1;
        flex-basis: 0;
      }

      .left {
        text-align: left;
      }

      .right {
        text-align: right;
      }

      ul {
        display: inline-block;
        list-style: none;

        li {
          display: inline-block;
          margin-right: 18px;
        }

        .active > a {
          color: var(--active-link-color-2);
        }
      }
    }

    g {
      color: #222;
    }

    .red {
      color: #C00;
    }

    .green {
      color: #0C0;
    }

    .red.percent {
      background-color: #630;
    }

    .green.percent {
      background-color: #363;
    }

    .promoted {
      max-width: 1100px;
      padding: 100px 0;
      margin-top: 40px;
    }

    table {
      border-collapse: collapse;
      margin: auto;

      /*thead td {
          color: var(--text-color-50-darker);
      }*/

      .fa-caret-down, .fa-caret-up {
        color: var(--text-color-25-darker);
      }

      caption {
        margin: 40px 0 20px;
        caption-side: top;
        vertical-align: middle;
        h2 {
          text-align: left;
        }
        label {
          text-align: center;
          font-size: large;
          float: left;
        }
        input {
          text-align: center;
          width: 50%;
        }
        > div {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid var(--light-border-color);
          padding-bottom: 10px;
          margin-bottom: 15px;

          ul {
            li {
              display: inline-block;

              &.active > a {
                color: var(--active-link-color-2);
              }
            }
          }
        }
      }

      thead td {
        text-align: center;
        vertical-align: middle;
      }

      .price-change {
        animation-duration: 3s;
        animation-name: pricechange;
        color: var(--button-hover-bg-color);
      }

      td {
        text-align: right;
        vertical-align: middle;
        padding: 3px 6px;
        border: 1px solid var(--light-border-color);
        white-space: nowrap;

        img.icon {
          height: 24px;
        }

        &.name {
          text-align: left;
          font-weight: bold;
          margin: 0 10px;

          > div {
            display: flex;
            align-items: center;

            img {
              user-select: none;
              padding: 0 7px;
            }
          }
        }

        &.no-r {
          border-right: none;
          padding-left: 16px;
        }

        &.no-l {
          border-left: none;
          padding-right: 16px;
        }

        &.r {
          text-align: right;
        }

        &.c {
          text-align: center;
        }

        &.active {
          font-weight: bold;
        }

        &.pointer {
          cursor: pointer;
        }
      }
    }
  }

  footer {
    line-height: 28px;
    font-size: 12px;
    margin-top: 36px;
    border-top: 1px solid var(--light-border-color);

    .inner {
      display: flex;
      justify-content: space-between;
      margin: 4px 6px;
    }

    .time {
      color: var(--text-color-25-darker);
    }

    ul.horiz > li {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      vertical-align: baseline;
      margin-right: 10px;
    }
  }
}

.navbar .nav .logo {
  margin-right: 18px;
}

@media only screen and (min-width: 0px) and (min-height: 0px) {
  div[id^="bsa-zone_1585587375466-4_123456"] {
    min-width: 320px;
    min-height: 50px;
  }
}
@media only screen and (min-width: 770px) and (min-height: 0px) {
  div[id^="bsa-zone_1585587375466-4_123456"] {
    min-width: 728px;
    min-height: 90px;
  }
}